import React, {Component} from 'react'
import {DatePicker, Input, Layout, message} from 'antd';
import 'antd/dist/antd.css'
import 'moment/locale/zh-cn';
import AMapLoader from '@amap/amap-jsapi-loader';

var markers=[]
var infoWindow=""
class Map extends Component {
    constructor(props) {
        super(props);
        this.state = {
            AMap: null,
            map: null,
            Markers: null,
            value: {
                "onlineCountStatistic":[

                ] ,
                "humExceptionStatistic": [

                ],
                "tempExceptionStatistic": [

                ],
                "offlineStatistic": [


                ],
                "normalStatistic": [


                ],
                "oneKeyAlarmStatistic": [

                ],
                "alarmStatistic": [

                ]
            },
            icons:[
                {
                    type: 'image',
                    image: '//safe-system.oss-cn-chengdu.aliyuncs.com/static/icons/positionIconR.png',
                    size: [30,30],
                    // 图片相对 position 的锚点，默认为 bottom-center
                    anchor: 'center',
                },
                {
                    type: 'image',
                    image: '//safe-system.oss-cn-chengdu.aliyuncs.com/static/icons/positionIconG.png',
                    size: [30,30],
                    // 图片相对 position 的锚点，默认为 bottom-center
                    anchor: 'center',
                },
                {
                    type: 'image',
                    image: '//safe-system.oss-cn-chengdu.aliyuncs.com/static/icons/positionIconY.png',
                    size: [30,30],
                    // 图片相对 position 的锚点，默认为 bottom-center
                },
                {
                    type: 'image',
                    image: 'https://safe-system.oss-cn-chengdu.aliyuncs.com/static/icons/waringIcon.gif',
                    size: [30,30],
                    // 图片相对 position 的锚点，默认为 bottom-center
                },

            ]
        }
    }
    componentDidMount(){
        this.initMap()
        //this.getMarkers()
    }



    render() {
        return (
            <div className="home" style={{height:"70%"}}>
                <div id="container" style={{width:"100%",height:"100%"}}>
                    {/* <img className="screen_body_center_pic" src={require('../../assets/screen/waringV.png')}/>
                    <div className="screen_body_center_waringV" >
                        <div style={{position:'relative',width:'100%',height:'0.25rem',background:'#000000', opacity: 0.61,zIndex:1}}>
                            <img style={{position:'relative',width:'0.2rem',height:'0.2rem',}} src={require('../../assets/screen/waring.png')} />
                        </div>
                        <div className="screen_body_center_waringC" >
                            <div id="hdVideo" className='hdVideo' style={{height:"100%"}}>
                                <canvas id="hdVideo-ts" className="hdVideo-ts"></canvas>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        ) 
    }

    setValue=(value)=>{
        this.setMarkers_(value)
    }

    /**
     *  map 1.0 版本{ 海量点标记 }
     */
    _setMarkers=()=>{
        console.log('更新点标记！')
        let value = this.state.value
        const AMap = this.state.AMap
        const map = this.state.map
        let styleObjectArr = [
            {
                url: 'https://safe-system.oss-cn-chengdu.aliyuncs.com/static/icons/positionIconR.png',  // 图标地址
                size: new AMap.Size(11,11),      // 图标大小
                anchor: new AMap.Pixel(5,5), // 图标显示位置偏移量，基准点为图标左上角
                zIndex: 4,
            },
            {
                url: 'https://safe-system.oss-cn-chengdu.aliyuncs.com/static/icons/positionIconG.png',  // 图标地址
                size: new AMap.Size(11,11),      // 图标大小
                anchor: new AMap.Pixel(5,5) ,// 图标显示位置偏移量，基准点为图标左上角
                zIndex: 5,
            },
            {
                url: 'https://safe-system.oss-cn-chengdu.aliyuncs.com/static/icons/positionIconY.png',  // 图标地址
                size: new AMap.Size(11,11),      // 图标大小
                anchor: new AMap.Pixel(5,5), // 图标显示位置偏移量，基准点为图标左上角
                zIndex: 6,
            },
        ]
        const massMarks = new AMap.MassMarks({
            zIndex: 1000, 	// 海量点图层叠加的顺序
            opacity:1,
            zooms: [3, 18],	 // 在指定地图缩放级别范围内展示海量点图层
            style: styleObjectArr 	//多种样式对象的数组
        });
        let data = this._setMassMarksData(value)
        massMarks.setData(data);
        console.log(massMarks.getData())
        massMarks.setMap(map);
    }

    _setMassMarksData = (value)=>{
        let data=[]
        /**
         *正常设备
         */
        value.normalStatistic.map(item=>{
            data.push({
                lnglat:item.coordinate.split(','),
                name:item.deviceId,
                id:item.deviceId,
                style:1,
            })
        })
        /**
         *掉线设备
         */
        value.offlineStatistic.map(item=>{
            data.push({
                lnglat:item.coordinate.split(','),
                name:item.deviceId,
                id:item.deviceId,
                style:2,
            })
        })
        /**
         *报警设备
         */
        value.alarmStatistic.map(item=>{
            data.push({
                lnglat:item.coordinate.split(','),
                name:item.deviceId,
                id:item.deviceId,
                style:0,
            })
        })

        return data

    }

    /**
     * map 2.0 版本{ 海量标注 }
     */

    setMarkers=()=>{
        console.log('更新点标记！')
        let value = this.state.value
        const AMap = this.state.AMap
        const map = this.state.map
        let icons = this.state.icons
        let data = this.setLabelMarkersData(value,AMap,icons)
        let labelsLayer = new AMap.LabelsLayer({
            zooms: [3, 20],
            zIndex: 1000,
            // 该层内标注是否避让
            //collision: true,
            // 设置 allowCollision：true，可以让标注避让用户的标注
            allowCollision: true,
        });
        // 批量添加 labelMarker
        labelsLayer.add(data);
        map.add(labelsLayer);
        console.log(1)
    }

    setLabelMarkersData = (value,AMap,icons)=> {
        let data = []
        /**
         *正常设备
         */
        value.normalStatistic.map(item => {
            data.push(new AMap.LabelMarker({
                    position: item.coordinate.split(','),
                    name: item.deviceId,
                    zIndex: 16,
                    icon: icons[1],
                }))
        })
        /**
         *掉线设备
         */
        value.offlineStatistic.map(item => {
            data.push(new AMap.LabelMarker({
                position: item.coordinate.split(','),
                name: item.deviceId,
                zIndex: 16,
                icon: icons[2]
            }))
        })
        /**
         *报警设备
         */
        value.alarmStatistic.map(item => {
            data.push(new AMap.LabelMarker({
                position: item.coordinate.split(','),
                name: item.deviceId,
                zIndex: 16,
                icon: icons[0]
            }))
        })
        return data
    }


    /**
     * 添加单个点标记
     */
    setMarkers_=(values)=>{
        let value = values
        const AMap = this.state.AMap;
        const map = this.state.map;
        let icons = this.state.icons
        if(markers){
            if(markers.length>0){
                map.remove(markers);
            }
        }

        // console.log('清除点标记！')
        icons = this.setIcons(icons,AMap)
        markers = this.setMarker(value,AMap,icons)
        if(map){
            map.add(markers);
        }
        // console.log('增加点标记！')

        // setTimeout(()=>{
        //     map.add(markers);
        //     console.log('增加点标记！')
        // },3000)


    }

    setIcons=(iconList,AMap)=>{
        let icons = []
        let icon
        iconList.map(item=>{
            if(AMap){
                icon = new AMap.Icon({
                    size: new AMap.Size(item.size[0], item.size[1]),    // 图标尺寸
                    image: item.image,  // Icon的图像
                    //imageOffset: new AMap.Pixel(0, -60),  // 图像相对展示区域的偏移量，适于雪碧图等
                    imageSize: new AMap.Size(item.size[0], item.size[1])   // 根据所设置的大小拉伸或压缩图片
                });
                icons.push(icon)
            }

        })
        return icons
    }
    liveVideo=(url)=>{
        const _that = this
        if(url.length>0){
            console.log(url)
            message.loading("视频加载中",0)
            const canvasTs= document.getElementById('hdVideo-ts')
            canvasTs.remove()
            const hdVideo =document.getElementById('hdVideo');
            let canvas = document.createElement('canvas')
            canvas.id = 'hdVideo-ts'
            canvas.style.width="100%"
            canvas.style.height="100%"
            hdVideo.appendChild(canvas)
        }else{
            message.info("暂无视频地址")
        }
    }
    markerClick=(e)=>{
        console.log(e)
        this.props.getVideo(e.target)
        infoWindow.setContent(e.target.content);
        infoWindow.open(this.state.map, e.target.getPosition());
    }
    setMarker=(value,AMap,icons)=>{
        if(!AMap){
            return
        }
        const _that = this
        // let marker = new AMap.Marker({
        //     position: new AMap.LngLat(116.405467, 39.907761),
        //     offset: new AMap.Pixel(-10, -10),
        //     icon: icon, // 添加 Icon 实例
        //     title: '北京',
        //     zoom: 13
        // });
        let data = []

        /**
         *正常设备
         */
        value.normalStatistic.map(item => {
            //let lnglat = this.gpsToamap(item.coordinate.split(',').reverse(),AMap)
            if(item.coordinate.length>0){
                let marker=new AMap.Marker({
                    //position: lnglat,
                    position: item.coordinate.split(',').reverse().reverse(),
                    icon: icons[1],
                    anchor:'bottom-center'
                })
                const liveVideo =item.liveVideo
                marker.content = `<div>
                                    <div>设备ID：${item.deviceId}</div>
                                    <div>设备型号：${item.deviceType}</div>
                                    <img src=${item.livePicture} alt="" style="width: 150px;height: 100px"/>
                                </div>`;
                marker.liveVideo=liveVideo
                marker.deviceId=item.deviceId

                marker.on("click",_that.markerClick)
                data.push(marker)
                
            }
        
            
        })
        /**
         *掉线设备
         */
        value.offlineStatistic.map(item => {
            //let lnglat = this.gpsToamap(item.coordinate.split(',').reverse(),AMap)
            if(item.coordinate.length>0){
                let marker=new AMap.Marker({
                    //position: lnglat,
                    position: item.coordinate.split(',').reverse().reverse(),
                    icon: icons[2],
                    anchor:'bottom-center'
                })
                const liveVideo =item.liveVideo
                marker.content = `<div>
                                    <div>设备ID：${item.deviceId}</div>
                                    <div>设备型号：${item.deviceType}</div>
                                    <img src=${item.livePicture} alt="" style="width: 150px;height: 100px"/>
                                </div>`;
                marker.liveVideo=liveVideo
                marker.deviceId=item.deviceId

                marker.on("click",_that.markerClick)

                data.push(marker)
            }
            
        })
        /**
         *报警设备
         */
        value.alarmStatistic.map(item => {
            //let lnglat = this.gpsToamap(item.coordinate.split(',').reverse(),AMap)
            if(item.coordinate.length>0){
                let marker=new AMap.Marker({
                    //position: lnglat,
                    position: item.coordinate.split(',').reverse().reverse(),
                    icon: icons[0],
                    anchor:'bottom-center'
                })
                const liveVideo =item.liveVideo
                marker.content = `<div>
                                    <div>设备ID：${item.deviceId}</div>
                                    <div>设备型号：${item.deviceType}</div>
                                    <img src=${item.livePicture} alt="" style="width: 150px;height: 100px"/>
                                </div>`;
                marker.liveVideo=liveVideo
                marker.deviceId=item.deviceId
                marker.location=item.location
                marker.livePicture=item.livePicture
                marker.on("click",_that.markerClick)
                data.push(marker)
            }
        })
        /**
         *异常设备
         */
        value.humExceptionStatistic.map(item => {
            //let lnglat = this.gpsToamap(item.coordinate.split(',').reverse(),AMap)
            if(item.coordinate.length>0){
                console.log(item)
                let marker=new AMap.Marker({
                    //position: lnglat,
                    position: item.coordinate.split(',').reverse().reverse(),
                    icon: icons[3],
                    anchor:'bottom-center'
                })
                const liveVideo =item.liveVideo
                marker.content = `<div>
                                    <div>设备ID：${item.deviceId}</div>
                                    <div>设备型号：${item.deviceType}</div>
                                    <img src=${item.livePicture} alt="" style="width: 150px;height: 100px"/>
                                </div>`;
                marker.liveVideo=liveVideo
                marker.deviceId=item.deviceId

                marker.on("click",_that.markerClick)

                data.push(marker)
            }
        })


        return data
    }

    gpsToamap= (GPS,AMap)=>{
        if(GPS.length===2){
            let gps = [Number(GPS[0]),Number(GPS[1])];
            let amapPos=[]
          AMap.convertFrom(gps, 'gps', function (status, result) {
                if (result.info === 'ok') {
                    let lnglats = result.locations;
                    amapPos = [lnglats[0].lng,lnglats[0].lat]
                }
            });
        }
    }


    /**
     * 初始化类容
     */
    initMap=()=>{
        const _that = this
        const mapInit = {
            zoom: 4,
            resizeEnable: true,
            pitch:75,
            // viewMode:'3D'
        }
        AMapLoader.load({
            "key": "6343992ee7ddab2af669c7274364274c",   // 申请好的Web端开发者Key，首次调用 load 时必填
            "version": "2.0",   // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
            "plugins": ["AMap.Autocomplete","AMap.PlaceSearch",'AMap.Geocoder',"AMap.ControlBar","AMap.ToolBar"]  //插件列表
        }).then((AMap)=>{
            //初始化地图对象，加载地图
            let map = new AMap.Map("container", mapInit);
            map.addControl(new AMap.ToolBar({
                // 简易缩放模式，默认为 false
                liteStyle: true
            }));
            _that.setState({map, AMap},()=>{
                // this.startSetInterval=setInterval(()=>{
                //     this.setMarkers_(this.state.value)
                // },2000)
            })
            infoWindow = new AMap.InfoWindow({offset: new AMap.Pixel(0, 0)});

            


        }).catch(e => {
            console.log(e);
            message.info("网络繁忙")
        })
    }

    initMarker=()=>{
        let AMap = this.state.AMap
        let map = this.state.map
       const marker = new AMap.Marker({
           position: new AMap.LngLat(116.39,39.9),
           offset: new AMap.Pixel(-10, -10),
           icon: '//vdata.amap.com/icons/b18/1/2.png', // 添加 Icon 图标 URL
           title: '北京'
       });
        map.add(marker);
        map.setFitView([marker])
    }
}

export default Map;
/**
 *
 */
import React, {Component} from 'react'
import {message,Input} from 'antd'
import AMapLoader from '@amap/amap-jsapi-loader';
const { Search } = Input;

export default class  extends Component {
    constructor(props) {
        super(props);
        this.state = {
            AMap: null,
            map: null,
            Markers: null,
            deviceNoPositionShow:false,
            value: {
                "onlineCountStatistic":[
                    {
                        "onlineNum": 270,
                        "offlineNum": 605,
                        "onlineRate": "vlvdbf"
                    }
                ] ,
                "humExceptionStatistic": [
                    {
                        "deviceId": "15",
                        "deviceType": "tdsebv",
                        "coordinate": "txmqat",
                        "deviceStatus": "yajj9k"
                    }
                ],
                "tempExceptionStatistic": [
                    {
                        "deviceId": "15",
                        "deviceType": "pem754",
                        "coordinate": "jick0r",
                        "deviceStatus": "sepsa6"
                    }
                ],
                "offlineStatistic": [
                    {
                        "deviceId": "D-1",
                        "deviceType": "",
                        "coordinate": "106.586708,29.570477",
                        "deviceStatus": "hq04ps"
                    },
                    {
                        "deviceId": "D-2",
                        "deviceType": "",
                        "coordinate": "106.576719,29.569221",
                        "deviceStatus": "hq04ps"
                    },
                    {
                        "deviceId": "D-3",
                        "deviceType": "",
                        "coordinate": "106.583186,29.573932",
                        "deviceStatus": "hq04ps"
                    }
                ],
                "normalStatistic": [
                    {
                        "deviceId": "D-4",
                        "deviceType": "",
                        "coordinate": "106.57715,29.574624",
                        "deviceStatus": "hq04ps"
                    },
                    {
                        "deviceId": "D-5",
                        "deviceType": "",
                        "coordinate": "106.578587,29.574121",
                        "deviceStatus": "hq04ps"
                    },
                    {
                        "deviceId": "D-6",
                        "deviceType": "",
                        "coordinate": "106.581749,29.573995",
                        "deviceStatus": "hq04ps"
                    }
                ],
                "oneKeyAlarmStatistic": [
                    // {
                    //     "deviceId": "D-7",
                    //     "deviceType": "",
                    //     "coordinate": "106.584049,29.582979",
                    //     "deviceStatus": "hq04ps"
                    // },
                    // {
                    //     "deviceId": "D-8",
                    //     "deviceType": "",
                    //     "coordinate": "106.581677,29.573681",
                    //     "deviceStatus": "hq04ps"
                    // },
                    // {
                    //     "deviceId": "D-9",
                    //     "deviceType": "",
                    //     "coordinate": "106.592457,29.572739",
                    //     "deviceStatus": "hq04ps"
                    // }
                ],
                "alarmStatistic": [
                    {
                        "deviceId": "D-10",
                        "deviceType": "",
                        "coordinate": "106.594541,29.567524",
                        "deviceStatus": "hq04ps"
                    },
                    {
                        "deviceId": "D-11",
                        "deviceType": "",
                        "coordinate": "106.573413,29.568844",
                        "deviceStatus": "hq04ps"
                    },
                    {
                        "deviceId": "D-12",
                        "deviceType": "",
                        "coordinate": "106.596409,29.572802",
                        "deviceStatus": "hq04ps"
                    }
                ]
            },
            icons:[
                {
                    type: 'image',
                    image: '//safe-system.oss-cn-chengdu.aliyuncs.com/static/icons/positionIconR.png',
                    size: [30,30],
                    // 图片相对 position 的锚点，默认为 bottom-center
                    anchor: 'center',
                },
                {
                    type: 'image',
                    image: '//wimg.588ku.com/gif620/20/09/30/5563906ebbf2b8815238bccd844d3e2f.gif n        ',
                    size: [30,30],
                    // 图片相对 position 的锚点，默认为 bottom-center
                    anchor: 'center',
                },
                {
                    type: 'image',
                    image: '//safe-system.oss-cn-chengdu.aliyuncs.com/static/icons/positionIconY.png',
                    size: [30,30],
                    // 图片相对 position 的锚点，默认为 bottom-center
                },

            ]
        }
        this.searchButton = React.createRef()
    }
    componentDidMount(){
        this.initMap()
    }

    render() {
        const {deviceNoPositionShow} = this.state;
        return (
            <div className="home" style={{width:'100%',height:500}} >
                <div style={{marginBottom:'10px'}}>
                    <Input  id="tipinput" ref={this.searchButton} style={{zIndex:100000}}  placeholder="请输入搜索地点"  />
                </div>
                {deviceNoPositionShow?<div style={{width:'100%',fontWeight:600,color:'red',fontSize:'20px',display:'flex',alignItems:'center'}}>暂无该设备经纬度！</div>:null}
                <div style={{width:'100%',height:'90%'}} id="container3" />
            </div>
        )
    }
    initMap=()=>{
        const _that = this;
        const mapInit = {
            zoom: 13,
            resizeEnable: true,
            pitch:75,
            center:[102.700462,25.000193],
            // resizeEnable: true
            // viewMode:'3D'
        }

        //输入提示

        AMapLoader.load({
            "key": "6343992ee7ddab2af669c7274364274c",   // 申请好的Web端开发者Key，首次调用 load 时必填
            "version": "2.0",   // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
            "plugins": ['AMap.Geocoder',"AMap.ControlBar","AMap.ToolBar"]  //插件列表
        }).then((AMap)=>{
            //初始化地图对象，加载地图
            let map = new AMap.Map("container3", mapInit);
                _that.setState({map, AMap},()=>{
                    map.on('click', this.getSetMark);
                    AMap.plugin(['AMap.AutoComplete','AMap.PlaceSearch'],function(){
                        var autoOptions = {
                            // 城市，默认全国
                            // city: "北京",
                            // 使用联想输入的input的id
                            input: "tipinput"
                        }
                        var autocomplete= new AMap.AutoComplete(autoOptions)

                        var placeSearch = new AMap.PlaceSearch({
                            // city:'北京',
                            map:map
                        })
                        autocomplete.on('select', function(e){
                            //TODO 针对选中的poi实现自己的功能
                            placeSearch.search(e.poi.name)
                        })
                    })
                })




        }).catch(e => {
            console.log(e);
            message.info("网络繁忙")
        })
    }

    initMarker=(pos)=>{
        console.log(pos)
        let lnglat = pos?.split(',');
        let AMap = this.state.AMap
        let map = this.state.map
        console.log(lnglat)
        if(lnglat?.length>1) {
            this.setState({deviceNoPositionShow:false})
            let icon = new AMap.Icon({
                size: new AMap.Size(30, 30),    // 图标尺寸
                image: 'https://safe-system.oss-cn-chengdu.aliyuncs.com/static/images/deviceIcon.svg',  // Icon的图像
                imageSize: new AMap.Size(30, 30)   // 根据所设置的大小拉伸或压缩图片
            });
            if(this.state.marker){
                console.log('清除点标记！')
                map.remove(this.state.marker);
            }
            const marker = new AMap.Marker({
                position: new AMap.LngLat(lnglat[0], lnglat[1]),
                offset: new AMap.Pixel(-0, -20),
                icon: icon, // 添加 Icon 图标 URL
            });
            map.add(marker);
            this.setState({marker:marker})
            map.setFitView([marker])
        }else {
            this.setState({deviceNoPositionShow:true})
            if(this.state.marker){
                console.log('清除点标记！')
                map.remove(this.state.marker);
            }
        }

    }

    handleSetMarker=(lnglat)=>{
        let AMap = this.state.AMap
        let map = this.state.map
        console.log(lnglat)
        if(lnglat.length>1) {
            this.setState({deviceNoPositionShow:false})
            let icon = new AMap.Icon({
                size: new AMap.Size(30, 30),    // 图标尺寸
                image: 'https://safe-system.oss-cn-chengdu.aliyuncs.com/static/images/deviceIcon.svg',  // Icon的图像
                imageSize: new AMap.Size(30, 30)   // 根据所设置的大小拉伸或压缩图片
            });
            if(this.state.marker){
                console.log('清除点标记！')
                map.remove(this.state.marker);
            }
            const marker = new AMap.Marker({
                position: new AMap.LngLat(lnglat.lat, lnglat.lng),
                // offset: new AMap.Pixel(-10, -10),
                icon: icon, // 添加 Icon 图标 URL
            });
            map.add(marker);
            this.setState({marker:marker})
            map.setFitView([marker])
        }else {
            this.setState({deviceNoPositionShow:true})
            if(this.state.marker){
                console.log('清除点标记！')
                map.remove(this.state.marker);
            }
        }

    }

    getSetMark=(value)=>{
        let pos = value.originEvent.lnglat.lng + ','+ value.originEvent.lnglat.lat;
        const {parent} = this.props;
        this.initMarker(pos);
        parent.setSelectSetMark(pos)
        console.log(value)
    }
}
import React, {Component} from 'react'
import {message} from 'antd';
import 'antd/dist/antd.css'
import 'moment/locale/zh-cn';
import AMapLoader from '@amap/amap-jsapi-loader';

var markers=[]
var infoWindow=""

class DeviceAllMap extends Component {
    constructor(props) {
        super(props);
        this.state = {
            AMap: null,
            map: null,
            Markers: null,
            value: {
                "onlineCountStatistic":[
                ] ,
                "humExceptionStatistic": [
                ],
                "tempExceptionStatistic": [
                ],
                "offlineStatistic": [
                ],
                "normalStatistic": [
                ],
                "oneKeyAlarmStatistic": [
                ],
                "alarmStatistic": [
                ]
            },
            deviceList:this.props.deviceList,
            icons:[
                {
                    type: 'image',
                    image: '//safe-system.oss-cn-chengdu.aliyuncs.com/static/icons/positionIconR.png',
                    size: [30,30],
                    // 图片相对 position 的锚点，默认为 bottom-center
                    anchor: 'center',
                },
                {
                    type: 'image',
                    image: '//safe-system.oss-cn-chengdu.aliyuncs.com/static/icons/positionIconG.png',
                    size: [30,30],
                    // 图片相对 position 的锚点，默认为 bottom-center
                    anchor: 'center',
                },
                {
                    type: 'image',
                    image: '//safe-system.oss-cn-chengdu.aliyuncs.com/static/icons/positionIconY.png',
                    size: [30,30],
                    // 图片相对 position 的锚点，默认为 bottom-center
                },
                {
                    type: 'image',
                    image: 'https://safe-system.oss-cn-chengdu.aliyuncs.com/static/icons/waringIcon.gif',
                    size: [30,30],
                    // 图片相对 position 的锚点，默认为 bottom-center
                },

            ]
        }
    }
    componentDidMount(){
        this.initMap()
    }

    render() {
        return (
            <div className="home" style={{height:"100%"}}>
                <div id="container" style={{width:"100%",height:"100%"}}>
                </div>
            </div>
        )
    }

    setValue=(value)=>{
        this.setMarkers_(value)
    }

    /**
     * 添加单个点标记
     */
    setMarkers_=(values)=>{
        let value = values
        const AMap = this.state.AMap;
        const map = this.state.map;
        let icons = this.state.icons
        if(markers.length>0){
            map.remove(markers);
        }
        icons = this.setIcons(icons,AMap)
        markers = this.setMarker(value,AMap,icons)
        map.add(markers);
    }

    setIcons=(iconList,AMap)=>{
        let icons = []
        let icon
        iconList.map(item=>{
            icon = new AMap.Icon({
                size: new AMap.Size(item.size[0], item.size[1]),    // 图标尺寸
                image: item.image,  // Icon的图像
                //imageOffset: new AMap.Pixel(0, -60),  // 图像相对展示区域的偏移量，适于雪碧图等
                imageSize: new AMap.Size(item.size[0], item.size[1])   // 根据所设置的大小拉伸或压缩图片
            });
            icons.push(icon)
        })
        return icons
    }

    markerClick=(e)=>{
        infoWindow.setContent(e.target.content);
        infoWindow.open(this.state.map, e.target.getPosition());
        var fatherVideo = document.getElementById(e.target.deviceId)
        var childList = fatherVideo.childNodes
        childList[1].addEventListener('click', this.lookVideo.bind(this,0,e.target), false)
        childList[3].addEventListener('click', this.lookVideo.bind(this,1,e.target), false)
    }
    lookVideo=(num,e)=>{
        this.props.getVideo(num,e)
    }

    setMarker= (value,AMap,icons)=>{
        const _that = this
        let data = []
        //正常设备
        value.offlineStatistic.map(async (item) => {
            if(item.coordinate.length>0){
                let positionRaw = item.coordinate.split(',').reverse().reverse();
                let marker1=new AMap.Marker({
                    // position: item.coordinate.split(',').reverse().reverse(),
                    position: positionRaw,
                    icon: icons[1]
                })
                marker1.content = `<div>
                                    <div>设备ID：${item.deviceId}</div>
                                    <img src=${item.livePicture} alt="" style="width: 150px;height: 100px"/>
                                    <div id=${item.deviceId}>
                                        <button name=${"video1"} alt="">123123</button>
                                        <button name=${"video2"} alt="">查看视频1</button>  
                                    </div>
                                </div>`;
                marker1.deviceId=item.deviceId
                marker1.deviceType=item.deviceType
                marker1.on("click",_that.markerClick)
                data.push(marker1)
                console.log("marker1", marker1);



                let promise = await _that.gps2coordinate(positionRaw, AMap);


                let marker=new AMap.Marker({
                    // position: item.coordinate.split(',').reverse().reverse(),
                    position: [""+promise[0].lng, ""+promise[0].lat],
                    icon: icons[1]
                })
                marker.content = `<div>
                                    <div>设备ID：${item.deviceId}</div>
                                    <img src=${item.livePicture} alt="" style="width: 150px;height: 100px"/>
                                    <div id=${item.deviceId}>
                                        <button name=${"video1"} alt="">查看视频0</button>
                                        <button name=${"video2"} alt="">查看视频1</button>  
                                    </div>
                                </div>`;
                marker.deviceId=item.deviceId
                marker.deviceType=item.deviceType
                marker.on("click",_that.markerClick)
                data.push(marker)
                console.log("marker", marker);
                console.log("data", data);
            }
        })
        //掉线设备
        // value.offlineStatistic.map(item => {
        //     //let lnglat = this.gpsToamap(item.coordinate.split(',').reverse(),AMap)
        //     if(item.coordinate.length>0){
        //         let marker=new AMap.Marker({
        //             //position: lnglat,
        //             position: item.coordinate.split(',').reverse().reverse(),
        //             icon: icons[2]
        //         })
        //         const liveVideo =item.liveVideo
        //         marker.content = `<div>
        //                             <div>设备ID：${item.deviceId}</div>
        //                             <img src=${item.livePicture} alt="" style="width: 150px;height: 100px"/>
        //                             <div id=${item.deviceId}>
        //                                 <button name=${"video1"} alt="">查看视频0</button>
        //                                 <button name=${"video2"} alt="">查看视频1</button>
        //                             </div>
        //                         </div>`;
        //         marker.liveVideo=liveVideo
        //         marker.deviceId=item.deviceId
        //         marker.deviceType=item.deviceType
        //         marker.on("click",_that.markerClick)
        //         data.push(marker)
        //     }
        // })
        /**
         *报警设备
         */
        value.alarmStatistic.map(item => {
            //let lnglat = this.gpsToamap(item.coordinate.split(',').reverse(),AMap)
            if(item.coordinate.length>0){
                let marker=new AMap.Marker({
                    //position: lnglat,
                    position: item.coordinate.split(',').reverse().reverse(),
                    icon: icons[0]
                })
                const liveVideo =item.liveVideo
                marker.content = `<div>
                                    <div>设备ID：${item.deviceId}</div>
                                    <img src=${item.livePicture} alt="" style="width: 150px;height: 100px"/>
                                    <div id=${item.deviceId}>
                                        <button name=${"video1"} alt="">查看视频0</button>
                                        <button name=${"video2"} alt="">查看视频1</button>  
                                    </div>
                                </div>`;
                marker.liveVideo=liveVideo
                marker.deviceId=item.deviceId
                marker.location=item.location
                marker.livePicture=item.livePicture
                marker.deviceType=item.deviceType
                marker.on("click",_that.markerClick)
                data.push(marker)
            }
        })
        /**
         *异常设备
         */
        value.humExceptionStatistic.map(item => {
            //let lnglat = this.gpsToamap(item.coordinate.split(',').reverse(),AMap)
            if(item.coordinate.length>0){
                let marker=new AMap.Marker({
                    //position: lnglat,
                    position: item.coordinate.split(',').reverse().reverse(),
                    icon: icons[3]
                })
                const liveVideo =item.liveVideo
                marker.content = `<div>
                                    <div>设备ID：${item.deviceId}</div>
                                    <img src=${item.livePicture} alt="" style="width: 150px;height: 100px"/>
                                    <div id=${item.deviceId}>
                                        <button name=${"video1"} alt="">查看视频0</button>
                                        <button name=${"video2"} alt="">查看视频1</button>  
                                    </div>
                                </div>`;
                marker.liveVideo=liveVideo
                marker.deviceId=item.deviceId
                marker.deviceType=item.deviceType
                marker.on("click",_that.markerClick)
                data.push(marker)
            }
        })
        return data
    }

    /**
     * 初始化类容
     */
    initMap=()=>{
        const _that = this
        const mapInit = {
            zoom: 4.5,
            resizeEnable: true,
            pitch:75,
            // viewMode:'3D'
        }
        AMapLoader.load({
            "key": "6343992ee7ddab2af669c7274364274c",   // 申请好的Web端开发者Key，首次调用 load 时必填
            "version": "2.0",   // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
            "plugins": ["AMap.Autocomplete","AMap.PlaceSearch",'AMap.Geocoder',"AMap.ControlBar","AMap.ToolBar"]  //插件列表
        }).then((AMap)=>{
            //初始化地图对象，加载地图
            let map = new AMap.Map("container", mapInit);
            map.addControl(new AMap.ToolBar({
                // 简易缩放模式，默认为 false
                liteStyle: true
            }));
            _that.setState({map, AMap},()=>{
            })
            infoWindow = new AMap.InfoWindow({offset: new AMap.Pixel(0, 0)});
        }).catch(e => {
            message.info("网络繁忙")
        })
    }

    initMarker=()=>{
        let AMap = this.state.AMap
        let map = this.state.map
        const marker = new AMap.Marker({
            position: new AMap.LngLat(116.39,39.9),
            offset: new AMap.Pixel(-10, -10),
            icon: '//vdata.amap.com/icons/b18/1/2.png', // 添加 Icon 图标 URL
            title: '北京'
        });
        map.add(marker);
        map.setFitView([marker])
    }

    gps2coordinate= (position, AMap)=>{
        return new Promise((resolve, reject)=>{
            AMap.convertFrom(position.map(p => Number.parseFloat(p)), 'gps', function (status, result) {
                if (result.info === 'ok') {
                    result = result.locations; // Array.<LngLat>
                    resolve(result)
                }
            });
        });
    }
}

export default DeviceAllMap;